var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-message-container",class:{
    'msg-left': !_vm.isMsgMine,
    'msg-right': _vm.isMsgMine,
  }},[_c('div',{staticClass:"card chat-message",class:{
      'msg-bg-right': _vm.isMsgMine,
      'msg-bg-left': !_vm.isMsgMine,
      'card-rounded-right-only': !_vm.isMsgMine,
      'card-rounded-left-only': _vm.isMsgMine,
    }},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-text message-text"},[_vm._v(" "+_vm._s(_vm.message.messageContent["chat-message"])+" ")]),_c('p',{staticClass:"card-text",class:{
          'meta-text-left': !_vm.isMsgMine,
          'meta-text-right': _vm.isMsgMine,
        }},[_c('small',{},[_vm._v("at "+_vm._s(_vm.messageTime12HrFormat)+" "),(!_vm.isMsgFromToday)?[_vm._v(" on "+_vm._s(this.date)+"/"+_vm._s(this.month)+" ")]:_vm._e(),(_vm.isMsgFromToday)?[_vm._v(" today ")]:_vm._e(),_vm._v(" by "+_vm._s(_vm.message.messageContent.username)+" ")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }